<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "tianYa",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#游戏的个性设置-战斗-高级-非战斗状态可招架-取消勾选(图2)',
                        ],
                        img:[
                            "com/init.png",
                            "com/feizhandou.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到隐士设置页",
                            "#按图1说明参数:",
                            "1.必填项-切换输出套路:天涯海阁无需输出套路",
                            "2.必填项-切换隐士套路:瀑布洗笔,每日比武需要使用隐士套路完成,根据快捷栏设置切换快捷键",
                            "3.必填项-技能:上面切换套路后,技能对应的快捷键,根据快捷栏设置",
                            "5.注意:隐士套路使用任务需要的技能即可,无需特殊设置,瀑布洗笔需要的四个技能都在快捷栏上",
                        ],
                        img:[
                            "com/yinshi.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到天涯海阁页",
                            "#按图1说明参数:",
                            "1.必填项-勾选要做的任务,如果已完成的任务需要取消勾选,已接取的任务需要放弃,让助手自动接取",
                            "2.接取任务时候,如果NPC的第一个任务不是要接取的任务,需要先手动接取,然后重新开始助手",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#可以把马哨,鹿,娇子,御风放到快捷栏上,移动时候自动使用",
                            "#角色移动到天涯海阁场景",
                            "#以上设置好,开始任务",
                        ],
                        img:[
                            "com/yinshi1.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
